<template>
  <a-form-model
    :model="query"
    class="search__form"
    :label-col="{ span: 5 }"
    :wrapper-col="{ span: 17 }"
    labelAlign="left"
  >
    <a-row>
      <a-col :span="8">
        <a-form-model-item label="公告标题">
          <a-input placeholder="公告标题" v-model="query.pubTitle" allowClear></a-input>
        </a-form-model-item>
      </a-col>
      <!-- <a-col :span="8">
        <a-form-model-item label="项目类型">
          <a-select
            :options="typeList"
            v-model="query.type"
            placeholder="请选择项目类型"
            allowClear
          ></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="发布时间">
          <a-range-picker value-format="YYYY-MM-DD" v-model="query.date" />
        </a-form-model-item>
      </a-col> -->
      <a-col :span="8">
        <a-form-model-item label="状态">
          <a-select
            :options="reStatus"
            v-model="query.status"
            placeholder="请选择状态"
            allowClear
          ></a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <div class="form_btn_box" v-perms="'search'">
          <a-button style="margin-right: 8px" @click="reset">重置</a-button>
          <a-button type="primary" @click="refresh">查询</a-button>
        </div>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>

import { reStatus } from '@/views/TenderingBidding/dict'

export default {
  name: 'ProductHeader',
  data () {
    return {
      query: {},
      reStatus: reStatus.map(item => ({
        ...item,
        value: item.value.join(',')
      }))
    }
  },
  mounted () {
    this.roleType = this.handleGetDict('roleType')
  },
  methods: {
    reset () {
      this.$set(this, 'query', {})
    },
    refresh () {
      this.$emit('refresh')
    },
    getQuery () {
      return {
        ...this.query
      }
    }
  }
}
</script>

<style lang="less">
.search__form {
  .form_btn_box {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
