export const typeList = [
  { label: '检修', value: '检修' },
  { label: '运行', value: '运行' },
  { label: '维护', value: '维护' },
  { label: '安装', value: '安装' },
  { label: '改造', value: '改造' }
]

export const zbPubStatus = [
  { label: '草稿', value: '0' },
  { label: '发布审核中', value: '1' },
  { label: '已发布', value: '2' },
  { label: '已结束', value: '3' },
  { label: '已驳回', value: '4' },
  { label: '已废标', value: '5' }
]

export const reStatus = [
  { label: '响应中', value: [0, 1, 4] },
  { label: '已中标', value: [2, 3] },
  { label: '已废标', value: [5] },
  { label: '未中标', value: [6] }
]
